import React from 'react';
import PropTypes from 'prop-types';
import '../../../styles/ComponentStyles/Skills.css';
import { Col, Row } from 'react-bootstrap';
import SkillIcon from './SkillIcon';
import { Breakpoints, useBreakpoint } from '../../_Core/Layouts/Main';

SkillsGroup.propTypes = {
	className: PropTypes.string,
	style: PropTypes.object,
	GroupName: PropTypes.string.isRequired,
	Skills: PropTypes.arrayOf(PropTypes.element).isRequired,
};

function SkillsGroup({ className = '', style, GroupName, Skills }) {
	const breakpoint = useBreakpoint();

	return (
		<Row className={`position-relative ${className ?? ''}`} style={{ ...style }}>
			<Col>
				<div className={`SkillGroup_Label ${breakpoint > Breakpoints.md ? 'side_label' : ''} user-select-none`}>
					<h2 className='text-center mb-1 text-capitalize'>{GroupName}</h2>
					<hr className='hr mt-1 rounded-pill w-100' />
				</div>

				<Row className='SkillGroup justify-content-center'>
					{Skills &&
						Skills.length > 0 &&
						Skills.map((skill, idx) => {
							return (
								<Col key={idx} xs={'auto'} className='p-2'>
									{skill}
								</Col>
							);
						})}
				</Row>
			</Col>
		</Row>
	);
}

SkillsGroup.Icon = SkillIcon;

export default SkillsGroup;
