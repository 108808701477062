import { useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { logEvent } from 'firebase/analytics';
import { useAnalytics } from '../../../../App';
import Cryptex from '../../../Fragments/Cryptex';

function CryptexPage() {
	useEffect(() => {
		logEvent(useAnalytics, 'page_view', { page_name: 'Cryptex' });
	}, []);

	return (
		<>
			<Container fluid className='w-100 h-100 p-0'>
				<Row className='h-auto min-h-100 align-content-center justify-content-center'>
					<Col xs='auto'>
						<Cryptex Solution='good' />
					</Col>
				</Row>
			</Container>
		</>
	);
}

export default CryptexPage;
