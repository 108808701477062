import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import '../../styles/ComponentStyles/Cryptex.css';
import { Letters } from '../../API/Utilities';
import { Col, Row } from 'react-bootstrap';
import MaterialIcon from './MaterialIcon';

Cryptex.propTypes = {
	Solution: PropTypes.string.isRequired,
	className: PropTypes.string,
	style: PropTypes.object,
};

//TODO: Make the dials seamlessly loop

function Cryptex({ className = '', style, Solution, DialOptions = [...Letters], Size = '10rem' }) {
	const [solved, solved_set] = useState(false);
	const [currentSelections, currentSelections_set] = useState([...Solution].map(() => DialOptions.getRandomItem()));

	const handleWheel = (e, index) => {
		lockScroll();

		let newSelections = [...currentSelections];

		const delta = e.deltaY > 0 ? 1 : -1;
		const currentIndex = DialOptions.indexOf(currentSelections[index]);
		const newIndex = currentIndex + delta < 0 ? DialOptions.length - 1 : currentIndex + delta > DialOptions.length - 1 ? 0 : currentIndex + delta;

		newSelections[index] = DialOptions[newIndex];
		currentSelections_set([...newSelections]);
	};

	const wheelTimeout = useRef();
	const lockScroll = () => {
		clearTimeout(wheelTimeout.current);
		wheelTimeout.current = setTimeout(() => {
			wheelTimeout.current = false;
		}, 300);
	};

	useEffect(() => {
		const cancelWheel = (e) => wheelTimeout.current && e.preventDefault();
		document.body.addEventListener('wheel', cancelWheel, { passive: false });
		return () => document.body.removeEventListener('wheel', cancelWheel);
	}, []);

	const solvedTimeout = useRef();
	useEffect(() => {
		clearTimeout(solvedTimeout.current);

		const isSolved = [...Solution].map((val, index) => val === currentSelections[index]).every((v) => v === true);

		if (isSolved) {
			solvedTimeout.current = setTimeout(() => {
				solved_set(true);
			}, 500);
		} else {
			solved_set(false);
		}
	}, [Solution, currentSelections, solved_set]);

	return (
		<div className={`Cryptex user-select-none ${className}`} style={{ ...style }}>
			<Row className='justify-content-center'>
				<Col xs={'auto'}>
					<Row className='h-100 align-content-center'>
						<Col xs={'auto'}>
							<div style={{ cursor: 'pointer' }} onClick={() => currentSelections_set([...Solution].map(() => DialOptions.getRandomItem()))}>
								<MaterialIcon Icon={'cached'} style={{ fontSize: Size, lineHeight: Size }} />
							</div>
						</Col>
					</Row>
				</Col>
				{[...Solution].map((val, index) => (
					<Col key={index} xs={'auto'}>
						<div
							className='Cryptex_Dial position-relative overflow-hidden border border-2 bg-glass bg-glass-dark'
							style={{ width: Size, height: `calc(${Size} * 3)`, cursor: 'n-resize', '--background-color-rgb': solved ? 'var(--nmd-tk-success-rgb)' : 'var(--nmd-tk-dark-rgb)' }}
							onWheel={(e) => handleWheel(e, index)}
						>
							<div className='Cryptex_Dial_Selection transform center-both border border-5 rounded-4' style={{ width: Size, height: Size }}></div>

							<div className='Cryptex_Dial_Options transform' style={{ '--top': `calc(${Size} * -1 * ${DialOptions.indexOf(currentSelections[index]) - 1})` }}>
								{[...DialOptions].map((option, index) => (
									<div key={index} className='text-center text-uppercase font-brand w-100' style={{ fontSize: Size, lineHeight: Size }}>
										{/* {currentSelections[index]} */}
										{option}
									</div>
								))}
							</div>
						</div>
					</Col>
				))}
			</Row>
		</div>
	);
}

export default Cryptex;
