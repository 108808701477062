import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { logEvent } from 'firebase/analytics';
import { useAnalytics } from '../../../../App';

function VeilIndex() {
	const navigate = useNavigate();
	useEffect(() => {
		logEvent(useAnalytics, 'page_view', { page_name: 'Veil_Fallback' });

		navigate('/');
	}, [navigate]);

	return <></>;
}

export default VeilIndex;
