import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import '../../styles/ComponentStyles/ProjectSpotlight.css';
import { gsap, Power1, useGSAP } from '../../API/gsap';
import { Card, Col, Row } from 'react-bootstrap';
import GlassCard from '../Fragments/GlassCard';

ProjectSpotlight.propTypes = {
	HeaderTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
	Tagline: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
	MainContent: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
	className: PropTypes.string,
	style: PropTypes.object,
};

function ProjectSpotlight({ className = '', style, scroller, trigger, fromRight = true, Skills, HeaderTitle, Tagline, MainContent }) {
	const projectSpotlightRef = useRef();
	const projectSpotlightSkillsRef = useRef();
	const projectSpotlightCardRef = useRef();
	const triggerRef = trigger ?? projectSpotlightRef;

	useGSAP(() => {
		if (!scroller?.current || !triggerRef?.current) return;

		gsap.timeline({
			scrollTrigger: {
				scroller: scroller.current,
				trigger: triggerRef.current,
				scrub: 1,
				start: 'top bottom',
				end: 'top 50%',
				toggleActions: 'play reverse restart reverse',
			},
		})
			.fromTo(
				projectSpotlightRef.current,
				{
					xPercent: fromRight ? 100 : -100,
				},
				{
					xPercent: 0,
					ease: Power1.out,
				}
			)
			.fromTo(
				projectSpotlightRef.current,
				{
					autoAlpha: 0,
				},
				{
					autoAlpha: 1,
					ease: Power1.out,
				},
				'<-25%'
			)
			.fromTo(
				projectSpotlightSkillsRef.current,
				{
					autoAlpha: 0,
					scale: 0,
				},
				{
					autoAlpha: 1,
					scale: 1,
					ease: Power1.out,
				},
				'>-10%'
			)
			.fromTo(
				projectSpotlightCardRef.current,
				{
					'--mask-start': 0,
					'--mask-end': 0,
				},
				{
					'--mask-start': 8.5,
					'--mask-end': 11,
					ease: Power1.out,
				},
				'<'
			);
	}, [scroller.current, triggerRef.current]);

	return (
		<Row ref={projectSpotlightRef} className={`ProjectSpotlight justify-content-center position-relative ${className ?? ''}`} style={{ ...style }}>
			<Col xs={12} xl className='h-auto p-0'>
				{Skills && Skills.length > 0 && (
					<div
						ref={projectSpotlightSkillsRef}
						className='ProjectSpotlight_Skills transform rounded-circle bg-glass bg-glass-dark shadow-pop'
						style={{
							width: '15rem',
							height: '15rem',
							'--top': '5rem',
							'--right': fromRight ? '' : '5rem',
							'--left': fromRight ? '5rem' : '',
							'--translateY': '-50%',
							'--translateX': fromRight ? '-50%' : '50%',
							'--zIndex': '10',
							'--blur': '25px',
							'--background-opacity': 0.35,
						}}
					>
						<div className='w-100 h-100 position-relative'>
							<Row className='justify-content-center h-100'>
								<Col xs={'auto'}>
									<Row className='justify-content-center align-content-center h-100'>
										<Col xs={'auto'} className={`${Skills && Skills.length === 3 ? 'pt-4' : Skills && Skills.length === 4 ? 'pt-2' : ''} px-4`}>
											<Row className='justify-content-center align-content-center gy-2 px-3'>
												{Skills.map((skill, idx) => {
													return (
														<Col key={idx} className='px-2 col-auto'>
															{skill}
														</Col>
													);
												})}
											</Row>
										</Col>
									</Row>
								</Col>
							</Row>
						</div>
					</div>
				)}

				<GlassCard
					ref={projectSpotlightCardRef}
					className={`ProjectSpotlight_Card ${!Skills || Skills.length === 0 ? 'noSkills' : ''} ${
						fromRight ? 'fromRight' : ''
					} w-100 py-4 px-2 position-relative rounded-5 shadow-pop-pseudo`}
					style={{ minHeight: '300px' }}
				>
					<Card.Body>
						<Row>
							<Col>
								<h1 className='mb-2 w-100 text-center font-brand lh-1' style={{ fontSize: '4rem', padding: '0 12.5rem' }}>
									{HeaderTitle}
								</h1>
							</Col>
						</Row>
						<Row>
							<Col>
								<hr className='hr rounded-pill' style={{ marginLeft: fromRight ? '5rem' : '15rem', marginRight: fromRight ? '15rem' : '5rem' }} />
							</Col>
						</Row>

						<Row>
							<Col>
								<h2 className='blockquote text-light fs-4 text-center mb-5' style={{ padding: '0 15rem' }}>
									{Tagline}
								</h2>
							</Col>
						</Row>

						<Row className='mb-3'>
							<Col>
								<span className='d-inline-block' style={{ fontSize: '1.25rem', padding: '0 10rem' }}>
									{MainContent}
								</span>
							</Col>
						</Row>
					</Card.Body>
				</GlassCard>
			</Col>
		</Row>
	);
}

export default ProjectSpotlight;
