import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import '../../styles/ComponentStyles/ActiveBackground.css';
import { convertNumberToRange, getInverseRgbData, getRandomNumerInRange, getRandomRgbData } from '../../API/Utilities';
import { gsap, Power1, useGSAP } from '../../API/gsap';

ActiveBackground.propTypes = {
	className: PropTypes.string,
};

const getLeftPath = () => {
	let points = 5;

	let start = { x: 25, y: 0 };
	let end = { x: 75, y: 100 };

	let pathString = `M -150 0 L ${start.x} ${start.y} `;

	let spanX = Math.abs(start.x - end.x);
	let spanY = Math.abs(start.y - end.y);

	let stepX = spanX / (points + 1);
	let stepY = spanY / (points + 1);

	for (let i = 0; i < points; i++) {
		let stepStartX = i * stepX + start.x;
		let stepStartY = i * stepY + start.y;

		let newX = getRandomNumerInRange(stepStartX, stepStartX + stepX);
		let newY = getRandomNumerInRange(stepStartY, stepStartY + stepY);
		pathString += `T ${newX} ${newY} `;
	}
	pathString += `T ${end.x} ${end.y} L 0 100`;

	return pathString;
};

const getRightPath = () => {
	let points = 5;

	let start = { x: 25, y: 100 };
	let end = { x: 75, y: 0 };

	let pathString = `M 100 100 L ${start.x} ${start.y} `;

	let spanX = Math.abs(start.x - end.x);
	let spanY = Math.abs(start.y - end.y);

	let stepX = spanX / (points + 1);
	let stepY = spanY / (points + 1);

	for (let i = 0; i < points; i++) {
		let stepStartX = i * stepX + start.x;
		let stepStartY = (points - i) * stepY;

		let newX = getRandomNumerInRange(stepStartX, stepStartX + stepX);
		let newY = getRandomNumerInRange(stepStartY, stepStartY + stepY);
		pathString += `T ${newX} ${newY} `;
	}
	pathString += `T ${end.x} ${end.y} L 150 0`;

	return pathString;
};

function ActiveBackground({ className = '', style, scroller, trigger }) {
	const [color, color_set] = useState(getRandomRgbData());
	const colorTimeout = useRef();

	const updateColor = useCallback((delay) => {
		color_set(getRandomRgbData());

		colorTimeout && colorTimeout.current && clearTimeout(colorTimeout.current);
		colorTimeout.current = setTimeout(() => {
			updateColor(Math.round(getRandomNumerInRange(3, 10)) * 1000);
		}, delay - 250);
	}, []);

	useEffect(() => {
		updateColor(5000);
	}, [updateColor]);

	const [leftPathString, leftPathString_set] = useState('');
	const [leftPathDelay, leftPathDelay_set] = useState(5000);
	const leftPathTimeout = useRef();

	const updateLeftPath = useCallback((delay, path) => {
		leftPathDelay_set(delay);
		leftPathString_set(path ?? getLeftPath());

		leftPathTimeout && leftPathTimeout.current && clearTimeout(leftPathTimeout.current);
		leftPathTimeout.current = setTimeout(() => {
			updateLeftPath(Math.round(getRandomNumerInRange(3, 10)) * 1000);
		}, delay - 250);
	}, []);

	useEffect(() => {
		updateLeftPath(3500, 'M -150 0 L 0 0 T 0 10 T 0 25 T 0 50 T 0 75 T 0 90 T 0 100 L 0 150');
	}, [updateLeftPath]);

	const [rightPathString, rightPathString_set] = useState('');
	const [rightPathDelay, rightPathDelay_set] = useState(5000);
	const rightPathTimeout = useRef();

	const updateRightPath = useCallback((delay, path) => {
		rightPathDelay_set(delay);
		rightPathString_set(path ?? getRightPath());

		rightPathTimeout && rightPathTimeout.current && clearTimeout(rightPathTimeout.current);
		rightPathTimeout.current = setTimeout(() => {
			updateRightPath(Math.round(getRandomNumerInRange(3, 10)) * 1000);
		}, delay - 250);
	}, []);

	useEffect(() => {
		updateRightPath(3500, 'M 150 100 L 100 100 T 100 90 T 100 75 T 100 50 T 100 25 T 100 10 T 100 0 L 150 0');
	}, [updateRightPath]);

	const leftPath = useRef();
	const rightPath = useRef();

	useGSAP(() => {
		gsap.timeline({
			scrollTrigger: {
				scroller: scroller.current,
				trigger: trigger.current,
				scrub: 1,
				start: '25% top',
				end: 'bottom -125%',
				toggleActions: 'play reverse restart reverse',
			},
		})
			.fromTo(
				leftPath.current,
				{
					xPercent: -100,
				},
				{
					xPercent: 0,
					ease: Power1.out,
				}
			)
			.fromTo(
				rightPath.current,
				{
					xPercent: 100,
				},
				{
					xPercent: 0,
					ease: Power1.out,
				},
				'>-70%'
			);
	}, [scroller.current, trigger.current]);

	return (
		<div
			className={`ActiveBackground transform pe-none user-select-none overflow-hidden bg-body ${className}`}
			style={{ '--top': '0px', '--left': '0px', '--right': '0px', '--bottom': '0px', zIndex: '-1', ...style }}
		>
			<svg
				ref={leftPath}
				className='ActiveBackground_SVG transform'
				width={'100%'}
				height={'100%'}
				xmlns='http://www.w3.org/2000/svg'
				viewBox='0 0 100 100'
				preserveAspectRatio='none'
				style={{
					WebkitMask: 'linear-gradient(to right, rgba(var(--nmd-tk-black-rgb), 1) 75%), transparent',
					mask: 'linear-gradient(to right, rgba(var(--nmd-tk-black-rgb), 1) 75%, transparent)',
				}}
			>
				<path
					d={leftPathString}
					className='transition'
					style={{
						'--transition-duration': `${leftPathDelay}ms`,
						'--fill-color': `rgba(${color.r}, ${color.g}, ${color.b}, 1)`,
						filter: `drop-shadow(25px 5px 0px rgba(0, 0, 0, 0.25))`,
						strokeOpacity: `${1 - convertNumberToRange(leftPathDelay, 3000, 10000, 0.65, 0.95)}`,
						strokeWidth: `${convertNumberToRange(leftPathDelay, 3000, 10000, 5, 40)}`,
						willChange: 'fill, stroke, stroke-opacity, stroke-width, transition',
					}}
					strokeLinejoin='round'
					fillOpacity='1'
					fillRule='nonzero'
					paintOrder='stroke'
				/>
			</svg>
			<svg
				ref={rightPath}
				className='ActiveBackground_SVG transform'
				width={'100%'}
				height={'100%'}
				xmlns='http://www.w3.org/2000/svg'
				viewBox='0 0 100 100'
				preserveAspectRatio='none'
				style={{
					WebkitMask: 'linear-gradient(to right, transparent, rgba(var(--nmd-tk-black-rgb), 1) 25%)',
					mask: 'linear-gradient(to right, transparent, rgba(var(--nmd-tk-black-rgb), 1) 25%)',
				}}
			>
				<path
					d={rightPathString}
					className='transition'
					style={{
						'--transition-duration': `${rightPathDelay}ms`,
						'--fill-color': `rgba(${getInverseRgbData(color).r}, ${getInverseRgbData(color).g}, ${getInverseRgbData(color).b}, 1)`,
						filter: `drop-shadow(-25px 5px 0px rgba(0, 0, 0, 0.25))`,
						strokeOpacity: `${1 - convertNumberToRange(rightPathDelay, 3000, 10000, 0.65, 0.95)}`,
						strokeWidth: `${convertNumberToRange(rightPathDelay, 3000, 10000, 5, 40)}`,
						willChange: 'fill, stroke, stroke-opacity, stroke-width, transition',
					}}
					strokeLinejoin='round'
					fillOpacity='1'
					fillRule='nonzero'
					paintOrder='stroke'
					filter=''
				/>
			</svg>
		</div>
	);
}

export default ActiveBackground;
