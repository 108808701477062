import { useEffect, useRef, useState } from 'react';
import '../../../styles/RouteStyles/NoahMillerDesign_BioQuote.css';
import '../../../styles/RouteStyles/NoahMillerDesign_ProjectSpotlights.css';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { logEvent } from 'firebase/analytics';
import { useAnalytics, useTitle } from '../../../App';
import { useProjectRoadmap } from '../../Sections/TopMenu';
import ProjectRoadmaps from '../ProjectRoadmaps/_AllProjectRoadmaps';
import ActiveBackground from '../../Sections/ActiveBackground';
import { Breakpoints, useBreakpoint, useContentBounds } from '../Layouts/Main';
import { gsap, Power1, useGSAP } from '../../../API/gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import Emblem from '../../Fragments/Emblem';
import TextRoller from '../../Fragments/TextRoller';
import GlassCard from '../../Fragments/GlassCard';
import Headshot from '../../Fragments/Headshot';
import SkillsGroup from '../../Fragments/Skills/SkillsGroup';
import Icons from '../../Fragments/Icons';
import SectionTitle from '../../Fragments/SectionTitle';
import ProjectSpotlight from '../../Sections/ProjectSpotlight';
import MaterialIcon from '../../Fragments/MaterialIcon';
import LinkButton from '../../Fragments/LinkButton';

function NoahMillerDesign() {
	useTitle('Independent Creator');
	useProjectRoadmap(<ProjectRoadmaps.NoahMillerDesign />);
	useEffect(() => {
		logEvent(useAnalytics, 'page_view', { page_name: 'Toolkit' });
	}, []);

	const [loaded, loaded_set] = useState(false);
	const [isPortrait, isPortrait_set] = useState();

	const { contentBounds } = useContentBounds();
	const breakpoint = useBreakpoint();

	useEffect(() => {
		isPortrait_set(contentBounds && contentBounds.width < contentBounds.height);
		if (contentBounds && !loaded) {
			loaded_set(true);
		}
	}, [contentBounds, loaded]);

	const scrollingContentRef = useRef();
	const bioContainerRef = useRef();
	const bioHeadshotRef = useRef();
	const bioCardRef = useRef();
	const bioStatsRef = useRef();
	const bioQuoteRef = useRef();
	const emblemRef = useRef();

	const [cleanBioQuote, cleanBioQuote_set] = useState(false);

	useGSAP(() => {
		if (!breakpoint || breakpoint <= Breakpoints.sm) return;

		gsap.timeline({
			scrollTrigger: {
				scroller: scrollingContentRef.current,
				trigger: bioContainerRef.current,
				scrub: 2.75,
				start: 'top bottom',
				end: 'center 60%',
				markers: false,
			},
		})
			.fromTo(
				bioHeadshotRef.current,
				{
					yPercent: -100,
					autoAlpha: 0,
				},
				{
					yPercent: 0,
					autoAlpha: 1,
					ease: Power1.out,
				}
			)
			.fromTo(
				bioCardRef.current,
				{
					yPercent: -100,
					autoAlpha: 0,
				},
				{
					yPercent: 0,
					autoAlpha: 1,
					ease: Power1.out,
				},
				'<'
			);

		gsap.timeline({
			scrollTrigger: {
				scroller: scrollingContentRef.current,
				trigger: bioContainerRef.current,
				scrub: 2.75,
				start: 'top 60%',
				end: 'center center',
				markers: false,
			},
		})
			.fromTo(
				bioStatsRef.current,
				{
					x: -100,
					autoAlpha: 0,
				},
				{
					x: 0,
					autoAlpha: 1,
					ease: Power1.out,
				}
			)
			.fromTo(
				bioQuoteRef.current,
				{
					x: 100,
					autoAlpha: 0,
				},
				{
					x: 0,
					autoAlpha: 1,
					ease: Power1.out,
				},
				'<'
			);

		ScrollTrigger.create({
			scroller: '.MainContent',
			trigger: bioContainerRef.current,
			start: 'top bottom',
			end: 'bottom top',
			onToggle: (self) => {
				//console.log('toggled, isActive:', self.isActive),
			},
			onUpdate: (self) => {
				// console.log('progress:', self.progress.toFixed(3), 'direction:', self.direction, 'velocity', self.getVelocity());
			},
		});
	}, [breakpoint]);

	// const { contextSafe } = useGSAP({ scope: testContainer });
	// const onClickGood = contextSafe(() => {
	// 	gsap.to(testingScroll.current, { rotation: '+=360' });
	// });

	return (
		<>
			<Container ref={scrollingContentRef} fluid className='w-100 h-100 p-0 overflow-y-scroll overflow-x-hidden' style={{}}>
				<ActiveBackground scroller={scrollingContentRef} trigger={emblemRef} />
				<div ref={emblemRef} className='position-relative w-100 h-100'>
					{loaded && (
						<Emblem
							Emblem={isPortrait ? 'Pawn' : 'NM'}
							className='fade-in'
							style={{
								'--fade-in-delay': '5.65s',
								'--fade-in-duration': '2.25s',
								mixBlendMode: 'exclusion',
							}}
						/>
					)}

					<div
						className='fade-in pe-none user-select-none transform center-both py-4 bg-glass bg-glass-colored text-center w-100'
						style={{
							'--fade-in-delay': '0.75s',
							'--fade-in-duration': '1.25s',
							'--blur': '15px',
							'--background-opacity': 0.2,
							'--background-color-rgb': 'var(--nmd-tk-body-rgb)',
						}}
					>
						<div
							style={{
								fontSize: 'clamp(1rem, calc(100vw / 10), 12.5rem)',
								'--title-font-weight': 700,
								'--title-outline-width': 'calc(clamp(1rem, calc(100vw / 20), 10rem) / 25)',
								'--title-outline-color': 'rgba(var(--nmd-tk-body-bg-rgb), 0.15)',
							}}
						>
							<span
								className='text-outline font-brand'
								style={{
									fontWeight: 'var(--title-font-weight)',
									'--outline-text-color': 'var(--nmd-tk-white)',
									'--outline-width': 'var(--title-outline-width)',
									'--outline-color': 'var(--title-outline-color)',
								}}
							>
								NoahMiller
							</span>
							<TextRoller
								style={{
									margin: '0 calc(clamp(1rem, calc(100vw / 20), 10rem) / 10)',
								}}
								RepeatDelay={7.5}
								StepDuration={0.5}
								StartDelay={3}
								Rollers={[
									<TextRoller.Roller
										Text={'Ideate'}
										className='text-outline font-brand'
										style={{
											'--outline-text-color': 'var(--nmd-tk-cyan)',
											'--outline-width': 'var(--title-outline-width)',
											'--outline-color': 'var(--title-outline-color)',
											fontWeight: 'var(--title-font-weight)',
										}}
									/>,
									<TextRoller.Roller
										Text={'Explore'}
										className='text-outline font-brand'
										style={{
											'--outline-text-color': 'var(--nmd-tk-teal)',
											'--outline-width': 'var(--title-outline-width)',
											'--outline-color': 'var(--title-outline-color)',
											fontWeight: 'var(--title-font-weight)',
										}}
									/>,
									<TextRoller.Roller
										Text={'Create'}
										className='text-outline font-brand'
										style={{
											'--outline-text-color': 'var(--nmd-tk-pink)',
											'--outline-width': 'var(--title-outline-width)',
											'--outline-color': 'var(--title-outline-color)',
											fontWeight: 'var(--title-font-weight)',
										}}
									/>,
									<TextRoller.Roller Text={''} />,
									<TextRoller.Roller
										Text={'Design'}
										className='text-outline font-brand'
										style={{
											'--outline-text-color': 'var(--nmd-tk-white)',
											'--outline-width': 'var(--title-outline-width)',
											'--outline-color': 'var(--title-outline-color)',
											fontWeight: 'var(--title-font-weight)',
										}}
									/>,
								]}
							/>
						</div>
					</div>
				</div>

				<Row className='d-xs-none h-25 justify-content-center align-content-center'>
					<Col xs={'auto'}></Col>
				</Row>

				<Row ref={bioContainerRef} className='min-h-100 justify-content-center align-content-center'>
					<Col md={12} lg={11} xl={11} xxl={9} className='p-4' style={{ maxWidth: '1200px', '--bio-size': '350px' }}>
						<Row className='justify-content-center g-5 mb-5'>
							<Col xs={12} lg={'auto'}>
								<Row className='justify-content-center'>
									<Col ref={bioHeadshotRef} xs={'auto'}>
										<Headshot Size='350px' />
									</Col>
								</Row>
							</Col>

							<Col xs={12} xl>
								<GlassCard
									ref={bioCardRef}
									className={`w-100 py-4 px-2 px-sm-5`}
									style={{ minHeight: 'var(--bio-size)', borderRadius: breakpoint > Breakpoints.sm ? 'calc(var(--bio-size) / 2)' : 'var(--nmd-tk-border-radius-xxl)' }}
								>
									<Card.Body className='d-flex flex-column justify-content-center'>
										<Row>
											<Col>
												<h1 className='mb-0 w-100 text-center text-capitalize font-brand lh-1' style={{ fontSize: '4rem' }}>
													Noah Miller
												</h1>
											</Col>
										</Row>
										<Row>
											<Col>
												<hr className='hr mx-n3 rounded-pill' />
											</Col>
										</Row>
										<Row className='mb-2 px-0 px-sm-2 px-md-4'>
											<Col>
												<p
													className='mb-0'
													style={{
														fontSize: '1.5rem',
													}}
												>
													I'm an independent designer who loves to create unique, engaging experiences. I work with a vast range of tech to craft memorable content that aims
													to&nbsp;leave you intrigued and coming back for more.
												</p>
											</Col>
										</Row>
									</Card.Body>
								</GlassCard>
							</Col>
						</Row>

						<Row className='justify-content-center g-5'>
							<Col xs={'auto'}>
								<GlassCard
									ref={bioStatsRef}
									className={`py-4 px-2 px-sm-5`}
									style={{
										width: breakpoint > Breakpoints.md ? 'var(--bio-size)' : 'auto',
										borderRadius: breakpoint > Breakpoints.sm ? 'calc(var(--bio-size) / 2)' : 'var(--nmd-tk-border-radius-xxl)',
									}}
								>
									<Card.Body className='d-flex flex-column justify-content-center mb-2'>
										<Row className='mt-2'>
											<Col>
												<h1 className='mb-4 w-100 text-center text-capitalize font-brand lh-1' style={{ fontSize: '3rem' }}>
													Core Skills
												</h1>
											</Col>
										</Row>

										{/* <Row>
											<Col>
												<hr className='hr mx-n3 mb-4 rounded-pill' />
											</Col>
										</Row> */}

										<Row>
											<Col>
												<SkillsGroup
													GroupName='Code'
													Skills={[
														// <SkillsGroup.Icon Image={Icons.DotNET} Label={'.NET'} ColorRGB={'23, 23, 23'} IconRatio={0.55} />,
														<SkillsGroup.Icon Image={Icons.React} Label={'React'} ColorRGB={'35, 39, 47'} IconRatio={0.65} Master={true} />,
														<SkillsGroup.Icon Image={Icons.Blazor} Label={'Blazor WASM / Server'} ColorRGB={'var(--nmd-tk-light-rgb)'} IconRatio={0.65} />,
														<SkillsGroup.Icon Image={Icons.HtmlCssJs} Label={'HTML 5 | CSS 3 | Javascript'} IconRatio={0.7} Master={true} />,
														<SkillsGroup.Icon Image={Icons.Firebase} Label={'Firebase'} ColorRGB={'18, 18, 18'} IconRatio={0.7} />,
														<SkillsGroup.Icon Image={Icons.NodeJs} Label={'Node.js'} ColorRGB={'135, 207, 48'} IconRatio={0.7} />,
													]}
												/>
											</Col>
										</Row>

										<Row>
											<Col>
												<SkillsGroup
													GroupName='Design'
													Skills={[
														<SkillsGroup.Icon Image={Icons.Fusion360} Label={'Autodesk Fusion 360'} ColorRGB={'var(--nmd-tk-light-rgb)'} IconRatio={0.6} Master={true} />,
														<SkillsGroup.Icon Image={Icons.Meshmixer} Label={'Autodesk Meshmixer'} ColorRGB={'70, 85, 101'} IconRatio={0.65} />,
														<SkillsGroup.Icon Image={Icons.Unity} Label={'Unity'} ColorRGB={'var(--nmd-tk-light-rgb)'} IconRatio={0.75} />,
														<SkillsGroup.Icon Image={Icons.XD} Label={'Adobe XD'} IconRatio={0.6} ColorRGB={'17, 17, 17'} />,
														<SkillsGroup.Icon Image={Icons.DevOps} Label={'Azure DevOps'} IconRatio={0.75} />,
													]}
												/>
											</Col>
										</Row>

										<Row>
											<Col>
												<SkillsGroup
													GroupName='Media'
													Skills={[
														<SkillsGroup.Icon Image={Icons.Audition} Label={'Adobe Audition'} IconRatio={0.6} ColorRGB={'17, 17, 17'} />,
														<SkillsGroup.Icon Image={Icons.Illustrator} Label={'Adobe Illustrator'} IconRatio={0.6} ColorRGB={'17, 17, 17'} />,
														<SkillsGroup.Icon Image={Icons.Photoshop} Label={'Adobe Photoshop'} IconRatio={0.6} ColorRGB={'17, 17, 17'} Master={true} />,
														<SkillsGroup.Icon Image={Icons.Premiere} Label={'Adobe Premiere'} IconRatio={0.6} ColorRGB={'17, 17, 17'} Master={true} />,
														<SkillsGroup.Icon Image={Icons.AfterEffects} Label={'Adobe After Effects'} IconRatio={0.6} ColorRGB={'17, 17, 17'} />,
													]}
												/>
											</Col>
										</Row>
									</Card.Body>
								</GlassCard>
							</Col>

							<Col className='flex-grow-1'>
								<GlassCard
									ref={bioQuoteRef}
									className={`w-100 h-100`}
									style={{ borderRadius: breakpoint > Breakpoints.sm ? 'calc(var(--bio-size) / 2)' : 'var(--nmd-tk-border-radius-xxl)' }}
								>
									<Card.Body className='d-flex flex-column justify-content-center'>
										<Row className='justify-content-center'>
											<Col xs={'auto'}>
												<figure className='m-0'>
													<blockquote
														className={`BioQuote ${cleanBioQuote ? 'cleanBioQuote' : ''} blockquote m-0 py-4 px-4 font-brand lh-sm fst-italic text-nowrap user-select-none`}
														style={{
															fontSize: breakpoint <= Breakpoints.sm ? '1.75rem' : '3rem',
														}}
													>
														<div className='BioQuote_Open' onMouseEnter={(e) => cleanBioQuote_set(true)} onMouseLeave={(e) => cleanBioQuote_set(false)}>
															"
														</div>
														<div className='w-100 pe-5 text-start'>A jack of all trades</div>
														<div className='w-100 ps-5 text-end'>
															is <span className='BioQuote_Master'>a master</span> of <span className='BioQuote_None'>none,</span>
														</div>
														<div className='w-100 pe-5 text-start'>
															but <span className='BioQuote_BO'>oftentimes</span> better
														</div>
														<div className='w-100 ps-5 text-end'>than a master of one.</div>
														<div className='BioQuote_Close' onMouseEnter={(e) => cleanBioQuote_set(true)} onMouseLeave={(e) => cleanBioQuote_set(false)}>
															"
														</div>
													</blockquote>
													<figcaption
														className='blockquote-footer m-0 px-2 px-sm-5 pb-4 text-center user-select-none'
														style={{
															fontSize: breakpoint < Breakpoints.sm ? '1.25rem' : '1.75rem',
														}}
													>
														<TextRoller
															RepeatDelay={0}
															StepDuration={0.5}
															StepHold={5}
															Rollers={[
																<TextRoller.Roller Text={'Someone Famous'} />,
																<TextRoller.Roller Text={'William Shakespeare'} className={'BioQuote_WS'} />,
																<TextRoller.Roller Text={'Benjamin Franklin'} className='BioQuote_BF' />,
																<TextRoller.Roller Text={'Noah Miller'} className={'BioQuote_Me'} />,
																<TextRoller.Roller Text={'Who Knows'} className='BioQuote_WK' />,
															]}
														/>
													</figcaption>
												</figure>
											</Col>
										</Row>
									</Card.Body>
								</GlassCard>
							</Col>
						</Row>
					</Col>
				</Row>

				{/* <Row className='min-h-75 justify-content-center align-content-center'>
					<Col md={12} lg={11} xl={11} xxl={9} className='p-4' style={{ maxWidth: '1200px' }}>
						<Row>
							<Col>
								<a href='mailto:noahmillerdesign@gmail.com' target='_blank' rel='noreferrer' className='text-decoration-none'>
									<div className='px-4 py-3 font-brand text-white bg-glass bg-glass-dark w-100 text-center text-uppercase h1 rounded-pill'>Get In Touch</div>
								</a>
							</Col>
						</Row>
					</Col>
				</Row> */}

				<Row className='min-h-25 justify-content-center align-content-end mb-5'>
					<Col md={12} lg={11} xl={11} xxl={9} className='p-4' style={{ maxWidth: '1200px' }}>
						<SectionTitle
							Text={
								<span>
									Project Sp
									<span className='projectSpotlightSwitch lightrays-hover-trigger position-relative'>
										o
										<MaterialIcon Icon={'emoji_objects'} className={'projectSpotlightSwitch_light'} style={{ fontSize: '1.75rem' }} />
									</span>
									tlights
								</span>
							}
							scroller={scrollingContentRef}
							className='lightrays lightrays-hover'
						/>
					</Col>
				</Row>

				<Row className='min-h-100 justify-content-center align-content-start'>
					<Col md={12} lg={11} xl={11} xxl={9} className='p-4' style={{ maxWidth: '1200px' }}>
						<ProjectSpotlight
							className='my-5'
							Skills={[
								<SkillsGroup.Icon Image={Icons.React} Label={'React'} ColorRGB={'35, 39, 47'} IconRatio={0.65} Master={true} />,
								<SkillsGroup.Icon Image={Icons.HtmlCssJs} Label={'HTML 5 | CSS 3 | JavaScript'} IconRatio={0.7} Master={true} />,
								<SkillsGroup.Icon Image={Icons.Firebase} Label={'Firebase'} ColorRGB={'18, 18, 18'} IconRatio={0.7} />,
							]}
							scroller={scrollingContentRef}
							HeaderTitle={'noahmillerdesign.com'}
							Tagline={'This website! Built from scratch using React and Firebase.'}
							MainContent={
								<>
									<Row>
										<Col>
											<Row className='mb-4'>
												<Col>
													<Row>
														<Col xs={'auto'}>
															<h3>Vision and Goal</h3>
															<hr className='hr mt-1 mx-n3 rounded-pill' />
														</Col>
													</Row>

													<p>
														This site has been designed and developed to showcase the kinds of interactive content that I enjoy creating. While I've certainly embraced the
														eccentric with its style, I've done my best to stay true to best practices with the technical. My goal is to share about the work that I've done
														in a fun, engaging way that sticks around in your memory longer than just until the end of lunch.
													</p>
												</Col>
											</Row>

											<Row className='mb-4'>
												<Col>
													<Row>
														<Col xs={'auto'}>
															<h3>Methodology</h3>
															<hr className='hr mt-1 mx-n3 rounded-pill' />
														</Col>
													</Row>

													<Row>
														<Col>
															<p>
																I've taken a content-centered, progressive exploration, development approach in creating this site. As I determine new information that
																I want to include I ideate through how it might best be conveyed. Once I settle on a general concept I jump right in and start creating
																a prototype. With the prototype completed, I break it apart into clean, reusable components so that they can be utilized anywhere in the
																site.
															</p>
															<p>
																Throughout this process I'm always looking for fun or interesting interactions to incorporate. These interactions are largely one-offs
																and are created using vanilla CSS and Javascript. While I want the interactions to be engaging, I'm also working to ensure that they add
																to the experience and don't take away from core functionality and usability.
															</p>
														</Col>
													</Row>
												</Col>
											</Row>

											<Row className='mb-4'>
												<Col>
													<Row>
														<Col>
															<h3>Tech Stack</h3>
															<hr className='hr mt-1 ms-n3 me-3 rounded-pill' />
														</Col>
													</Row>

													<Row>
														<Col>
															<h4>Front-end</h4>

															<ul className='list-unstyled'>
																<li className='mb-3'>
																	<LinkButton
																		Image={Icons.React}
																		IconRatio={1.25}
																		Text={'React'}
																		style={{ '--background-rgb': '35, 39, 47', '--color-rgb': '88, 196, 220' }}
																	/>
																</li>

																<li className='mb-3'>
																	<LinkButton Image={Icons.Bootstrap} IconRatio={1.45} Text={'Bootstrap'} style={{ '--background-rgb': '110, 43, 243' }} />
																</li>

																<li className='mb-3'>
																	<LinkButton
																		Image={Icons.HtmlCssJs}
																		IconRatio={1.25}
																		Text={'HTML | CSS | JS'}
																		style={{ '--background-rgb': 'var(--nmd-tk-dark-rgb)' }}
																	/>
																</li>
															</ul>
														</Col>

														<Col>
															<h4>Back-end</h4>

															<ul className='list-unstyled'>
																<li className='mb-3'>
																	<LinkButton
																		Image={Icons.Firebase}
																		IconRatio={1.5}
																		Text={'Firebase'}
																		style={{ '--background-rgb': '18, 18, 18', '--color-rgb': '255, 196, 0' }}
																	/>
																</li>

																<li className='mb-3'>
																	<LinkButton
																		Image={Icons.NodeJs}
																		IconRatio={1.5}
																		Text={'Node.js'}
																		style={{ '--background-rgb': '135, 207, 48', '--color-rgb': '73, 74, 71' }}
																	/>
																</li>
															</ul>
														</Col>
													</Row>
												</Col>

												<Col xs={'auto'}>
													<Row>
														<Col>
															<h5 className='mb-3'>Additional Skills Utilized</h5>
														</Col>
													</Row>

													<Row className='justify-content-center'>
														<Col xs={'auto'} className='p-2'>
															<SkillsGroup.Icon Image={Icons.Photoshop} Label={'Adobe Photoshop'} IconRatio={0.6} ColorRGB={'17, 17, 17'} Master={true} />
														</Col>

														<Col xs={'auto'} className='p-2'>
															<SkillsGroup.Icon Image={Icons.Illustrator} Label={'Adobe Illustrator'} IconRatio={0.6} ColorRGB={'17, 17, 17'} />
														</Col>
													</Row>
												</Col>
											</Row>
										</Col>
									</Row>
								</>
							}
						/>

						<div style={{ height: 'clamp(5rem, 10%, 15rem)' }}></div>

						<ProjectSpotlight
							className='my-5'
							fromRight={false}
							Skills={[
								<SkillsGroup.Icon Image={Icons.Fusion360} Label={'Autodesk Fusion 360'} ColorRGB={'var(--nmd-tk-light-rgb)'} IconRatio={0.6} Master={true} />,
								<SkillsGroup.Icon Image={Icons.Meshmixer} Label={'Autodesk Meshmixer'} ColorRGB={'70, 85, 101'} IconRatio={0.65} />,
							]}
							scroller={scrollingContentRef}
							HeaderTitle={'3D Modeling & Design'}
							Tagline={'Free 3D models, mostly for 3D printing.'}
							MainContent={
								<>
									<Row>
										<Col>
											<Row className='mb-4'>
												<Col>
													<Row>
														<Col xs={'auto'}>
															<h3>Background and Experience</h3>
															<hr className='hr mt-1 mx-n3 rounded-pill' />
														</Col>
													</Row>

													<p>
														I first got started with 3D modeling using Autodesk Inventor to do mechanical design for competitive robotics, all the way back in my sophomore
														year of highschool. Later on, during my time in undergrad, I transitioned to the more creatively free-form softwares of Autodesk Maya and Maxon
														ZBrush. It was during this time that I was introduced to 3D printing. The more that I got involved in 3D printing, the more I transitioned to
														Autodesk Fusion 360 and Meshmixer.
													</p>
													<p>Since then I've published over 100 free 3D models, and created even more that will be published in the future.</p>
												</Col>
											</Row>

											<Row className='mb-4'>
												<Col>
													<Row>
														<Col>
															<h3>Tools and Platforms</h3>
															<hr className='hr mt-1 ms-n3 me-3 rounded-pill' />
														</Col>
													</Row>

													<Row>
														<Col>
															<h4>Software</h4>

															<ul className='list-unstyled'>
																<li className='mb-3'>
																	<LinkButton
																		Image={Icons.Fusion360}
																		IconRatio={1.25}
																		Text={'Fusion 360'}
																		style={{ '--background-rgb': 'var(--nmd-tk-light-rgb)', '--color-rgb': '202, 116, 44' }}
																	/>
																</li>

																<li className='mb-3'>
																	<LinkButton
																		Image={Icons.Meshmixer}
																		IconRatio={1.5}
																		Text={'Meshmixer'}
																		style={{ '--background-rgb': '70, 85, 101', '--color-rgb': '221, 220, 236' }}
																	/>
																</li>
															</ul>
														</Col>

														<Col>
															<h4>Websites</h4>

															<ul className='list-unstyled'>
																<li className='mb-3'>
																	<LinkButton
																		Image={Icons.Thangs}
																		IconRatio={1.75}
																		Href={'https://thangs.com/designer/NoahMillerDesign'}
																		Text={'Thangs'}
																		style={{ '--background-rgb': 'var(--nmd-tk-light-rgb)', '--color-rgb': '123, 123, 130' }}
																	/>
																</li>

																<li className='mb-3'>
																	<LinkButton
																		Image={Icons.Thingiverse}
																		IconRatio={1.25}
																		Href={'https://www.thingiverse.com/noahmillerdesign/designs'}
																		Text={'Thingiverse'}
																		style={{ '--background-rgb': 'var(--nmd-tk-light-rgb)', '--color-rgb': '36, 139, 251' }}
																	/>
																</li>
															</ul>
														</Col>
													</Row>
												</Col>
											</Row>
										</Col>
									</Row>
								</>
							}
						/>

						{/*

						<ProjectSpotlight
							Skills={[
								<SkillsGroup.Icon Image={Icons.AfterEffects} Label={'Adobe After Effects'} IconRatio={0.6} ColorRGB={'17, 17, 17'} />,
								<SkillsGroup.Icon Image={Icons.Illustrator} Label={'Adobe Illustrator'} IconRatio={0.6} ColorRGB={'17, 17, 17'} />,
								<SkillsGroup.Icon Image={Icons.Photoshop} Label={'Adobe Photoshop'} IconRatio={0.6} ColorRGB={'17, 17, 17'} Master={true} />,
							]}
							HeaderTitle={'Moxious YouTube Intro'}
							scroller={scrollingContentRef}
							className='my-5'
						/> */}
					</Col>
				</Row>

				<Row className='min-h-50 justify-content-center align-content-end'>
					<Col md={12} lg={11} xl={11} xxl={9} className='p-4' style={{ maxWidth: '1200px' }}></Col>
				</Row>

				<Row className='min-h-50 justify-content-center align-content-end'>
					<Col md={12} lg={11} xl={11} xxl={9} className='p-4' style={{ maxWidth: '1200px' }}></Col>
				</Row>

				<Row
					className='h-200 justify-content-center bg-glass'
					style={{
						'--blur': '50px',
						WebkitMaskImage: 'linear-gradient(to bottom, transparent, rgba(var(--nmd-tk-black-rgb), 1) 60%)',
						maskImage: 'linear-gradient(to bottom, transparent, rgba(var(--nmd-tk-black-rgb), 1))',
					}}
				>
					<Col md={10} lg={8} xl={6}></Col>
				</Row>

				{/* <Row
					className='h-100 justify-content-center bg-glass'
					style={{
						'--blur': '50px',
						background: 'rgba(var(--nmd-tk-body-rgb), 0.75)',
					}}
				>
					<Col md={10} lg={8} xl={6}></Col>
				</Row>

				<Row
					className='h-100 justify-content-center bg-glass'
					style={{
						'--blur': '50px',
						background: 'rgba(var(--nmd-tk-body-rgb), 0.75)',
					}}
				>
					<Col md={10} lg={8} xl={6}></Col>
				</Row>

				<Row
					className='h-100 justify-content-center'
					style={{
						background: 'linear-gradient(to bottom, rgba(var(--nmd-tk-body-rgb), 0.75), rgba(var(--nmd-tk-black-rgb), 1))',
					}}
				>
					<Col md={10} lg={8} xl={6}></Col>
				</Row>

				<Row
					className='h-100 justify-content-center'
					style={{
						background: 'rgba(var(--nmd-tk-black-rgb), 1)',
						WebkitMaskImage: 'radial-gradient(circle, transparent 15%, rgba(var(--nmd-tk-black-rgb), 1) 50%)',
						maskImage: 'radial-gradient(circle, transparent 50%, rgba(var(--nmd-tk-black-rgb), 1) 10%)',
					}}
				>
					<Col md={10} lg={8} xl={6}></Col>
				</Row> */}
			</Container>
		</>
	);
}

export default NoahMillerDesign;
