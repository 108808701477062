import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { gsap, Power1, useGSAP } from '../../API/gsap';

SectionTitle.propTypes = { className: PropTypes.string, style: PropTypes.object, Text: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired };

function SectionTitle({ className = '', style, scroller, trigger, Text }) {
	const sectionTitleRef = useRef();
	const triggerRef = trigger ?? sectionTitleRef;

	useGSAP(() => {
		if (!scroller?.current || !triggerRef?.current) return;

		gsap.timeline({
			scrollTrigger: {
				scroller: scroller.current,
				trigger: triggerRef.current,
				scrub: 1,
				start: 'top 60%',
				end: 'top 25%',
				toggleActions: 'play reverse restart reverse',
			},
		})
			.fromTo(
				sectionTitleRef.current,
				{
					yPercent: -100,
				},
				{
					yPercent: 0,
					ease: Power1.out,
				}
			)
			.fromTo(
				sectionTitleRef.current,
				{
					autoAlpha: 0,
				},
				{
					autoAlpha: 1,
					ease: Power1.out,
				},
				'<-25%'
			);
	}, [scroller, triggerRef]);

	return (
		<h1
			ref={sectionTitleRef}
			className={`w-100 mb-5 text-center cursor-default ${className ?? ''}`}
			style={{
				fontSize: 'clamp(1rem, calc(100vw / 10), 8rem)',
				'--title-font-weight': 700,
				'--title-outline-width': 'calc(clamp(1rem, calc(100vw / 20), 10rem) / 25)',
				'--title-outline-color': 'rgba(var(--nmd-tk-body-bg-rgb), 0.15)',
				...style,
			}}
		>
			<span
				className='text-outline font-brand'
				style={{
					fontWeight: 'var(--title-font-weight)',
					'--outline-text-color': 'var(--nmd-tk-white)',
					'--outline-width': 'var(--title-outline-width)',
					'--outline-color': 'var(--title-outline-color)',
				}}
			>
				{Text}
			</span>
		</h1>
	);
}

export default SectionTitle;
