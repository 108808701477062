import React from 'react';
import PropTypes from 'prop-types';
import MaterialIcon from './MaterialIcon';
import '../../styles/ComponentStyles/LinkButton.css';
import ImageCustom from './ImageCustom';
import { Col, Row } from 'react-bootstrap';

LinkButton.propTypes = {
	className: PropTypes.string,
	style: PropTypes.object,
};

function LinkButton({ className = '', style, Href, Text, NewWindow = true, Image, Icon, IconRatio = 0.75 }) {
	return (
		<div
			data-href={Href ? Href : null}
			target={`${NewWindow && Href ? '_blank' : ''}`}
			rel='noreferrer'
			className={`LinkButton btn btn-primary rounded-pill w-100 border-0 fs-4 py-0 ${!Href ? 'cursor-default' : ''} ${className ?? ''}`}
			style={{ ...style }}
			onClick={() => {
				if (Href) window.open(Href, NewWindow ? '_blank' : '');
			}}
		>
			<Row>
				<Col className='px-2 d-flex flex-column justify-content-center align-content-start'>
					<Row>
						<Col xs={'auto'} className='mt-n1'>
							{Image ? (
								<ImageCustom src={Image} alt={Text} className={''} style={{ height: `calc(1rem * ${IconRatio})`, objectFit: 'contain' }} />
							) : Icon ? (
								<MaterialIcon Icon={Icon} className={''} style={{ fontSize: `calc(100% * ${IconRatio})` }} />
							) : (
								<></>
							)}
						</Col>
					</Row>
				</Col>

				<Col xs={'auto'} className='p-0 text-center'>
					{Text}
				</Col>

				<Col className='px-2'>
					<Row className='justify-content-end'>
						<Col xs={'auto'}>{Href && <MaterialIcon Icon={'arrow_outward'} className={'fs-6'} />}</Col>
					</Row>
				</Col>
			</Row>
		</div>
	);
}

export default LinkButton;
