import { Outlet, useLoaderData, useLocation, useNavigate, useOutletContext } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';

Veil.Loader = async function loader({ request }) {
	const url = new URL(request.url);
	const key = url.searchParams.get('key');
	return { key };
};

function Veil({ userProfile, userProfilePrivate, ValidateKey }) {
	const navigate = useNavigate();
	const location = useLocation();

	const { key } = useLoaderData();
	const [keyValid, keyValid_set] = useState();

	useEffect(() => {
		ValidateKey(key, location.pathname, (result) => keyValid_set(result));
	}, [key, location, ValidateKey]);

	useEffect(() => {
		if (keyValid !== undefined && !keyValid) {
			navigate('/');
		}
	}, [keyValid, navigate]);

	const contentRef = useRef();
	const [contentBounds, contentBounds_set] = useState();

	useEffect(() => {
		window.addEventListener('resize', (e) => {
			if (contentRef && contentRef.current) {
				let currentBounds = contentRef.current.getBoundingClientRect();
				contentBounds_set(currentBounds);
			}
		});
	}, []);

	useEffect(() => {
		if (contentRef.current) {
			window.dispatchEvent(new Event('resize'));
		}
	}, [contentRef]);

	return (
		<>
			{keyValid && (
				<section
					ref={contentRef}
					className={'MainContent w-100 h-100 text-body bg-black'}
					style={{
						overflowY: 'auto',
						overflowX: 'hidden',
						'--content-width': (contentBounds && contentBounds.width) || 0,
						'--content-height': (contentBounds && contentBounds.height) || 0,
					}}
				>
					<Outlet context={{ contentBounds, key }} />
				</section>
			)}
		</>
	);
}

export const useContentBounds = () => {
	return useOutletContext();
};

export const Breakpoints = { xs: 0, sm: 576, md: 768, lg: 992, xl: 1200, xxl: 1400 };

export function useBreakpoint() {
	const [breakpoint, breakpoint_set] = useState();

	const { contentBounds } = useContentBounds();
	useEffect(() => {
		if (contentBounds && contentBounds.width) {
			const width = contentBounds.width;
			switch (true) {
				case width >= Breakpoints.xxl:
					breakpoint_set(Breakpoints.xxl);
					break;
				case width >= Breakpoints.xl:
					breakpoint_set(Breakpoints.xl);
					break;
				case width >= Breakpoints.lg:
					breakpoint_set(Breakpoints.lg);
					break;
				case width >= Breakpoints.md:
					breakpoint_set(Breakpoints.md);
					break;
				case width >= Breakpoints.sm:
					breakpoint_set(Breakpoints.sm);
					break;
				case width >= Breakpoints.xs:
					breakpoint_set(Breakpoints.xs);
					break;
				default:
					breakpoint_set(undefined);
			}
		}
	}, [contentBounds]);

	return breakpoint;
}

export default Veil;
